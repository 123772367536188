import ReactGA from 'react-ga4'

const pageView = () => {
  console.log('pageView', document.title)
  ReactGA.send({ hitType: 'pageview', page: `/${document.title}` })
}
const sendUser = (username) => {
  ReactGA.event({
    category: 'User',
    action: 'LoggedIn User',
    label: `${username} - ${document.title}`,
  })
}
const gaEvents = {
  pageView,
  sendUser,
}
export default gaEvents
