import _ from 'lodash'
import store from 'store'
import * as reduxStore from 'index'

const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export async function login(jwtToken) {
  let allowedUsers = reduxStore.store.getState('user')
  allowedUsers = _.get(allowedUsers, 'user.allowedUsers')

  const allowedUserEmails = _.map(allowedUsers, 'email')
  const userResponse = parseJwt(jwtToken)
  const userEmail = _.get(userResponse, 'email')
  if (_.includes(allowedUserEmails, userEmail)) {
    store.set('accessToken', jwtToken)
    return true
  }

  return false
}

export async function currentAccount() {
  const jwt = store.get('accessToken')
  let allowedUsers = reduxStore.store.getState('user')
  allowedUsers = _.get(allowedUsers, 'user.allowedUsers')
  const allowedUserEmails = _.map(allowedUsers, 'email')

  if (!jwt) {
    return false
  }
  const userResponse = parseJwt(jwt)

  const userEmail = _.get(userResponse, 'email')

  if (_.includes(allowedUserEmails, userEmail)) {
    return userResponse
  }

  return false
}

export async function logout() {
  store.remove('accessToken')
  return true
}
