import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import gaEvents from 'gaEvents'
import { connect, useSelector } from 'react-redux'

import Layout from 'layouts'

const isSmallScreen = window.innerWidth < 576

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: '/dashboard/alpha',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },
  {
    path: '/dashboard/beta',
    Component: lazy(() => import('pages/dashboard/beta')),
    exact: true,
  },
  {
    path: '/dashboard/gamma',
    Component: lazy(() => import('pages/dashboard/gamma')),
    exact: true,
  },
  {
    path: '/dashboard/crypto',
    Component: lazy(() => import('pages/dashboard/crypto')),
    exact: true,
  },
  {
    path: '/apps',
    Component: lazy(() => import('pages/apps')),
    exact: true,
  },
  {
    path: '/apps/profile',
    Component: lazy(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    Component: lazy(() => import('pages/apps/gallery')),
    exact: true,
  },
  {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/mail',
    Component: lazy(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/extra-apps',
    Component: lazy(() => import('pages/extra-apps')),
    exact: true,
  },
  {
    path: '/extra-apps/github-explore',
    Component: lazy(() => import('pages/extra-apps/github-explore')),
    exact: true,
  },
  {
    path: '/extra-apps/github-discuss',
    Component: lazy(() => import('pages/extra-apps/github-discuss')),
    exact: true,
  },
  {
    path: '/extra-apps/digitalocean-droplets',
    Component: lazy(() => import('pages/extra-apps/digitalocean-droplets')),
    exact: true,
  },
  {
    path: '/extra-apps/digitalocean-create',
    Component: lazy(() => import('pages/extra-apps/digitalocean-create')),
    exact: true,
  },
  {
    path: '/extra-apps/google-analytics',
    Component: lazy(() => import('pages/extra-apps/google-analytics')),
    exact: true,
  },
  {
    path: '/extra-apps/wordpress-post',
    Component: lazy(() => import('pages/extra-apps/wordpress-post')),
    exact: true,
  },
  {
    path: '/extra-apps/wordpress-posts',
    Component: lazy(() => import('pages/extra-apps/wordpress-posts')),
    exact: true,
  },
  {
    path: '/extra-apps/wordpress-add',
    Component: lazy(() => import('pages/extra-apps/wordpress-add')),
    exact: true,
  },
  {
    path: '/extra-apps/todoist-list',
    Component: lazy(() => import('pages/extra-apps/todoist-list')),
    exact: true,
  },
  {
    path: '/extra-apps/jira-dashboard',
    Component: lazy(() => import('pages/extra-apps/jira-dashboard')),
    exact: true,
  },
  {
    path: '/extra-apps/jira-agile-board',
    Component: lazy(() => import('pages/extra-apps/jira-agile-board')),
    exact: true,
  },
  {
    path: '/extra-apps/helpdesk-dashboard',
    Component: lazy(() => import('pages/extra-apps/helpdesk-dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce',
    Component: lazy(() => import('pages/ecommerce')),
    exact: true,
  },
  {
    path: '/ecommerce/dashboard',
    Component: lazy(() => import('pages/ecommerce/dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce/orders',
    Component: lazy(() => import('pages/ecommerce/orders')),
    exact: true,
  },
  {
    path: '/ecommerce/product-catalog',
    Component: lazy(() => import('pages/ecommerce/product-catalog')),
    exact: true,
  },
  {
    path: '/ecommerce/product-details',
    Component: lazy(() => import('pages/ecommerce/product-details')),
    exact: true,
  },
  {
    path: '/ecommerce/cart',
    Component: lazy(() => import('pages/ecommerce/cart')),
    exact: true,
  },
  {
    path: '/batches',
    Component: lazy(() => import('pages/batches')),
    exact: true,
  },
  {
    path: '/locations',
    Component: lazy(() => import('pages/locations')),
    exact: true,
  },
  {
    path: '/locations/:id',
    Component: lazy(() => import('pages/locations/details')),
    exact: true,
  },
  {
    path: '/batches/:id',
    Component: lazy(() => import('pages/batches/details')),
    exact: true,
  },
  {
    path: '/items',
    Component: lazy(() => import('pages/items')),
    exact: true,
  },
  {
    path: '/items/:id',
    Component: lazy(() => import('pages/items/details')),
    exact: true,
  },
  {
    path: '/purchases',
    Component: lazy(() => import('pages/purchases')),
    exact: true,
  },
  {
    path: '/purchases/:id/invoice',
    Component: lazy(() => import('pages/purchases/invoice')),
    exact: true,
  },
  {
    path: '/deliveries',
    Component: lazy(() => import('pages/delivery')),
    exact: true,
  },
  {
    path: '/movements',
    Component: lazy(() => import('pages/movement')),
    exact: true,
  },
  {
    path: '/deals',
    Component: lazy(() => import('pages/deals')),
    exact: true,
  },
  {
    path: '/deals/:id',
    Component: lazy(() => import('pages/deals/details')),
    exact: true,
  },
  {
    path: '/movements/:transactionId',
    Component: lazy(() => import('pages/movement/details')),
    exact: true,
  },
  {
    path: '/movements/:transactionId/invoice',
    Component: lazy(() => import('pages/movement/invoice')),
    exact: true,
  },
  {
    path: '/movements/request/:id',
    Component: lazy(() => import('pages/movementRequests/details')),
    exact: true,
  },
  {
    path: '/parties',
    Component: lazy(() => import('pages/party')),
    exact: true,
  },
  {
    path: '/parties/:id',
    Component: lazy(() => import('pages/party/details')),
    exact: true,
  },
  {
    path: '/brokers',
    Component: lazy(() => import('pages/broker')),
    exact: true,
  },
  {
    path: '/brokers/:id',
    Component: lazy(() => import('pages/broker/details')),
    exact: true,
  },
  {
    path: '/deliveries/:id',
    Component: lazy(() => import('pages/delivery/details')),
    exact: true,
  },
  {
    path: '/deliveries/request/:id',
    Component: lazy(() => import('pages/deliveryRequests/details')),
    exact: true,
  },
  {
    path: '/deliveries/:id/invoice',
    Component: lazy(() => import('pages/delivery/invoice')),
    exact: true,
  },
  {
    path: '/users',
    Component: lazy(() => import('pages/users')),
    exact: true,
  },
  {
    path: '/users/:id',
    Component: lazy(() => import('pages/users/details')),
    exact: true,
  },
  {
    path: '/adjustments',
    Component: lazy(() => import('pages/adjustments')),
    exact: true,
  },
  {
    path: '/discrepant-invoices',
    Component: lazy(() => import('components/DiscrepantInvoices')),
    exact: true,
  },
  {
    path: '/discrepant-invoices/:id',
    Component: lazy(() => import('pages/DiscrepantInvoices/Details')),
    exact: true,
  },
  {
    path: '/ui-kits/antd',
    Component: lazy(() => import('pages/ui-kits/antd')),
    exact: true,
  },
  {
    path: '/ui-kits/bootstrap',
    Component: lazy(() => import('pages/ui-kits/bootstrap')),
    exact: true,
  },
  {
    path: '/widgets',
    Component: lazy(() => import('pages/widgets')),
    exact: true,
  },
  {
    path: '/widgets/general',
    Component: lazy(() => import('pages/widgets/general')),
    exact: true,
  },
  {
    path: '/widgets/lists',
    Component: lazy(() => import('pages/widgets/lists')),
    exact: true,
  },
  {
    path: '/widgets/tables',
    Component: lazy(() => import('pages/widgets/tables')),
    exact: true,
  },
  {
    path: '/widgets/charts',
    Component: lazy(() => import('pages/widgets/charts')),
    exact: true,
  },
  {
    path: '/cards',
    Component: lazy(() => import('pages/cards')),
    exact: true,
  },
  {
    path: '/cards/basic',
    Component: lazy(() => import('pages/cards/basic')),
    exact: true,
  },
  {
    path: '/cards/tabbed',
    Component: lazy(() => import('pages/cards/tabbed')),
    exact: true,
  },
  {
    path: '/tables',
    Component: lazy(() => import('pages/tables')),
    exact: true,
  },
  {
    path: '/tables/antd',
    Component: lazy(() => import('pages/tables/antd')),
    exact: true,
  },
  {
    path: '/tables/bootstrap',
    Component: lazy(() => import('pages/tables/bootstrap')),
    exact: true,
  },
  {
    path: '/charts',
    Component: lazy(() => import('pages/charts')),
    exact: true,
  },
  {
    path: '/charts/chartistjs',
    Component: lazy(() => import('pages/charts/chartistjs')),
    exact: true,
  },
  {
    path: '/charts/chartjs',
    Component: lazy(() => import('pages/charts/chartjs')),
    exact: true,
  },
  {
    path: '/charts/C3',
    Component: lazy(() => import('pages/charts/C3')),
    exact: true,
  },
  {
    path: '/icons',
    Component: lazy(() => import('pages/icons')),
    exact: true,
  },
  {
    path: '/icons/feather-icons',
    Component: lazy(() => import('pages/icons/feather-icons')),
    exact: true,
  },
  {
    path: '/icons/fontawesome',
    Component: lazy(() => import('pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linearicons-free',
    Component: lazy(() => import('pages/icons/linearicons-free')),
    exact: true,
  },
  {
    path: '/icons/icomoon-free',
    Component: lazy(() => import('pages/icons/icomoon-free')),
    exact: true,
  },
  {
    path: '/advanced/form-examples',
    Component: lazy(() => import('pages/advanced/form-examples')),
    exact: true,
  },
  {
    path: '/advanced/email-templates',
    Component: lazy(() => import('pages/advanced/email-templates')),
    exact: true,
  },
  {
    path: '/advanced/pricing-tables',
    Component: lazy(() => import('pages/advanced/pricing-tables')),
    exact: true,
  },
  {
    path: '/advanced/invoice',
    Component: lazy(() => import('pages/advanced/invoice')),
    exact: true,
  },
  {
    path: '/advanced/utilities',
    Component: lazy(() => import('pages/advanced/utilities')),
    exact: true,
  },
  {
    path: '/advanced/grid',
    Component: lazy(() => import('pages/advanced/grid')),
    exact: true,
  },
  {
    path: '/advanced/typography',
    Component: lazy(() => import('pages/advanced/typography')),
    exact: true,
  },
  {
    path: '/advanced/colors',
    Component: lazy(() => import('pages/advanced/colors')),
    exact: true,
  },
  {
    path: '/nested',
    Component: lazy(() => import('pages/nested')),
    exact: true,
  },
  {
    path: '/nested/1',
    Component: lazy(() => import('pages/nested/1')),
    exact: true,
  },
  {
    path: '/nested/2',
    Component: lazy(() => import('pages/nested/2')),
    exact: true,
  },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

if (isSmallScreen) {
  routes.push({
    path: '/pending-deals',
    Component: lazy(() => import('pages/PendingDeals')),
    exact: true,
  })
}

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  const defaultURL = isSmallScreen ? 'pending-deals' : 'dashboard'
  const user = useSelector((state) => state.user)

  useEffect(() => {
    const unlisten = history.listen(() => {
      gaEvents.sendUser(user.name)
      gaEvents.pageView()
    })

    return () => unlisten()
  }, [history])

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to={`/${defaultURL}`} />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
