const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOGIN_GOOGLE: 'user/LOGIN_GOOGLE',
  REGISTER: 'user/REGISTER',
  LOAD_ALLOWED_USERS: 'user/LOAD_ALLOWED_USERS',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  SET_ALLOWED_USERS: 'user/SET_ALLOWED_USERS'
}

export default actions
