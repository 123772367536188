const URL_JSON = {
  production: 'https://brahma-api.bloomimpex.com',
  development: 'http://brahma-backend-dev.ap-south-1.elasticbeanstalk.com',
  local: 'http://127.0.0.1:3000',
}

const BASE_URL = URL_JSON[process.env.REACT_APP_CUSTOM_NODE_ENV]

export const post = (url, body) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const request = new Request(`${BASE_URL}${url}`, {
    method: 'post',
    headers,
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseBody) => {
      if (responseBody.status) {
        return Promise.resolve(responseBody.result)
      }

      return Promise.reject(responseBody.error)
    })
}

export const get = (url) => {
  const request = new Request(`${BASE_URL}${url}`, {
    method: 'get',
  })

  return fetch(request)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network error')
      }
      return response.json()
    })
    .then((responseBody) => {
      if (responseBody.status) {
        return Promise.resolve(responseBody.result)
      }

      return Promise.reject(responseBody.error)
    })
    .catch((err) => {
      console.error('There was a problem with the Fetch operation:', err)
    })
}

export const postImageURL = (url, body) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const request = new Request(`${BASE_URL}${url}`, {
    method: 'post',
    headers,
    body: JSON.stringify(body),
  })
  return fetch(request).then((res) => res)
}

export const put = (url, body = {}) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const request = new Request(`${BASE_URL}${url}`, {
    method: 'put',
    headers,
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseBody) => {
      if (responseBody.status) {
        return Promise.resolve(responseBody.result)
      }

      return Promise.reject(responseBody.error)
    })
}

export const patch = (url, body) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const request = new Request(`${BASE_URL}${url}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseBody) => {
      if (responseBody.status) {
        return Promise.resolve(responseBody.result)
      }

      return Promise.reject(responseBody.error)
    })
}

export const deleteR = (url, body) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const request = new Request(`${BASE_URL}${url}`, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseBody) => {
      if (responseBody.status) {
        return Promise.resolve(responseBody.result)
      }

      return Promise.reject(responseBody.error)
    })
}
