import './dateRangePicker.scss'
import React, { useContext, useEffect } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment/moment'
import { DateRangeContext } from 'context/DateProvider'

const { RangePicker } = DatePicker

const DateRangePicker = () => {
  const { setDateRange } = useContext(DateRangeContext)
  const StartDate = moment('2024-05-01')
  const defaultDatesValues = [StartDate, null]

  useEffect(() => {
    setDateRange(defaultDatesValues)
  }, [])

  const handleDatesRange = (dates) => {
    if (dates === null) {
      setDateRange([])
      return
    }

    const startDate = moment(dates[0]).format()
    const endDate = moment(dates[1]).format()
    setDateRange([startDate, endDate])
  }

  return (
    <div>
      <RangePicker defaultValue={defaultDatesValues} onChange={handleDatesRange} />
    </div>
  )
}

export default DateRangePicker
