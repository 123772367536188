import React, { createContext, useState } from 'react'

export const DateRangeContext = createContext(null)

const DateRangeProvider = ({ children }) => {
  const [dateRange, setDateRange] = useState([])

  return (
    <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
      {children}
    </DateRangeContext.Provider>
  )
}

export default DateRangeProvider
