import { get, post } from './index'

export const getAllUsers = (filter = '') => {
  return get(`/users${filter}`)
}

export const getUserById = (id) => {
  return get(`/user/${id}`)
}

export const createUser = (payload) => {
  return post('/user', payload)
}

export const setUserPremission = (payload) => {
  return post(`/userPermission`, payload)
}
